import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { ConfigService } from '../config.service';
import { LocalizationService } from '../localization.service';
import { CommonService } from '../common.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
    selector: 'app-reauthenticate-form',
    templateUrl: './reauthenticate-form.component.html',
    styleUrls: ['./reauthenticate-form.component.scss']
})
export class ReauthenticateFormComponent implements OnInit {

    constructor(
        private router: Router,
        private data: DataService,
        private config: ConfigService,
        private route: ActivatedRoute,
        private localization: LocalizationService,
        public common: CommonService
    ) {
        common.title = this.tr('access.reauth.title');
        common.clearError();
    }

    sessionReady = false;
    discovered = false;
    realms = [];
    //multipleRealms = false;
    selectedRealm = '';
    username = '';
    sessionCount = 3;
    closeSessions = false;
    codeSent = false;
    auth = null;
    user = null;
    codeText = '';
    allowRedirect = true;
    bothMFAEnabled = false;
    preferredMethod = '';
    phoneNumber = '';
    userDetails = null;
    invalidCode = false;
    ssoRedirection = false;
    emailRequired = false;
    passwordRequired = false;
    codeRequired = false;
    setupmfaFlow = false;
    mfaEnabled = false;
    originUrl = '';
    targetUrl = '';
    mode: ProgressSpinnerMode = 'indeterminate';
    value = 32;
    color: ThemePalette = 'primary';
    
    ngOnInit() {
        this.initChecks();
    }

    selectValue(val) {
        this.selectedRealm = val;
    }

    saveRealm() {
        this.config.setCookie('AccessRealm', this.selectedRealm, 1);
        this.accessReAuthRedirect();
    }

    async initChecks() {
        this.originUrl = this.config.getParameterByName("origin");

        // if target url is not present consider the target to be same as origin
        if (this.config.getParameterByName("target")) {
            this.targetUrl = this.config.getParameterByName("target");
        } else {
            this.targetUrl = this.config.getParameterByName("origin")
        }

        let amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
        if (amplifyInfo) {
            this.data.configureAmplify(amplifyInfo.client_id, amplifyInfo.user_pool);
        }

        let action = this.data.reAuthenticationAction;
        if(action && action === "setupmfa") {
            this.setupmfaFlow = true;
        }

        let loggedIn = await this.data.isLoggedIn(
            this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
            this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
            this.config.getParameterByName('target') ? this.config.getParameterByName('target') : 'NA',
            this.config.getParameterByName('loginType') ? this.config.getParameterByName('loginType') : 'NA',
            this.config.getParameterByName('ops') ? this.config.getParameterByName('ops') : 'NA'
        );
        if (loggedIn) {
            this.user = await this.data.getCurrentUser();
            this.username = this.user.attributes.email;
            let context = this;
            this.user.getUserData(function (err, data) {
                if (data.UserMFASettingList && data.UserMFASettingList.length > 0) {
                    context.mfaEnabled = true;
                }
            });
            this.discovered = true;
        } else {
            /*var email = this.config.getParameterByName("email");
            if (!!email) {
                this.username = this.config.getParameterByName("email");
                this.discover(email);
            }
            if (this.config.getParameterByName("loginType") == "SSO" && this.config.getParameterByName("customerID")) {
                await this.directIDPLogin(email, this.config.getParameterByName("customerID"))
            }*/
            this.discovered = false;
            let url = '/login?redirect=true&origin=' + decodeURIComponent(this.config.getParameterByName('origin'));
            url += '&target='+this.targetUrl;
            this.router.navigateByUrl(url);
        }
    }

    async redirectToIDP(username: string, userPool: string, clientId: string, identifier: string) {
        let wellKnownUrl = `https://cognito-idp.${userPool.split("_")[0]}.amazonaws.com/${userPool}/.well-known/openid-configuration`;
        let result = await this.data.getWellKnownKeys(wellKnownUrl);

        // Save some for future use
        localStorage.setItem("amplifyInfo", JSON.stringify({ client_id: clientId, user_pool: userPool, tokenUrl: result["token_endpoint"], origin: this.config.getParameterByName('origin'), session_count: this.sessionCount }));

        // Determine and build SAML endpoint
        let authorization_endpoint = result["authorization_endpoint"];
        authorization_endpoint += `?response_type=code&client_id=${clientId}&redirect_uri=${this.data.getDomain()}/callout&identity_provider=${identifier}`;
        this.ssoRedirection = true;
        // Redirect to for SAML auth
        location.href = authorization_endpoint;
    }

    async discover(email: string) {
        this.common.beginProgress();
        if (email == ""){
            this.emailRequired = true;
            this.common.stopProgress();
            return;
        }

        try {
            console.log("Discovering");
            let username = email.toLowerCase();
            let discoveredResponse = await this.data.discover(username, this.config.getParameterByName("customerID") || "", "RE-AUTH").toPromise();
            let decodedToken = new JwtHelperService().decodeToken(discoveredResponse.token); 
            let discovered = decodedToken.userData ? JSON.parse(decodedToken.userData) : {};

            if (discovered) {
                if(discovered.sessionCount)
                    this.sessionCount = discovered.sessionCount;
                let loginType = discovered.loginType;
                let clientId = discovered.client_id;
                let userPool = discovered.user_pool;
                this.data.username = email;
                if (loginType.toLowerCase() == "adfssaml") {
                    let identifier = discovered.identifier;
                    this.redirectToIDP(email, userPool, clientId, identifier);
                }
                else {
                    localStorage.setItem("amplifyInfo", JSON.stringify({ client_id: clientId, user_pool: userPool, session_count: this.sessionCount }));
                    this.discovered = true;
                    await this.data.configureAmplify(clientId, userPool);
                }

                this.common.clearError();
            } else {
                this.discovered = true;
            }
        }
        catch (err) {
            this.common.clearError();
            this.discovered = true;
        }

        this.common.stopProgress();
    }

    accessRedirect() {
        try {
            var origin = this.config.getParameterByName('origin');
            if (origin && this.config.isAllowedToRedirect(origin) && this.allowRedirect) {
                this.ssoRedirection = true;
                location.href = decodeURIComponent(origin);
            }
            else {
                this.sessionReady = true;
                this.common.setError(this.tr('access.common.sessionReady'));
            }
        }
        catch (err) {
            this.common.setError(this.tr('access.common.notAllowed'));
        }
    }

    accessReAuthRedirect() {
        try {
            // if action is setupmfa, take to setupmfa page
            if (this.setupmfaFlow) {
                this.setupMFA();
            } else {
                // else takes the user to the origin after re-auth, TODO should be target, a new parameter
                if (this.config.isAllowedToRedirect(this.targetUrl) && this.allowRedirect) {
                    this.ssoRedirection = true;
                    location.href = decodeURIComponent(this.targetUrl);
                } else {
                    this.sessionReady = true;
                    this.common.setError(this.tr('access.common.sessionReady'));
                }
            }
        }
        catch (err) {
            this.common.setError(this.tr('access.common.notAllowed'));
        }
    }

    async ngAfterViewInit() {

    }

    private maskPhoneumber(phone) {
        let last4 = phone.substring(phone.length - 4);
        let mask = phone.substring(4, phone.length - 5).replace(/\d/g, "*");
        return mask + last4;
    }

    public async login(username: string, password: string, code: string) {
        console.log("Start login");
        if(password == "" && this.data.password == ""){
            this.passwordRequired = true;
            this.common.stopProgress();
            return;
        }
        username = username.toLowerCase();
        try {
            this.common.clearError();
            this.invalidCode = false;
            this.common.beginProgress();
            //if code already sent
            if (this.user && this.codeSent) {
                if(code == ""){
                    this.codeRequired = true;
                    this.common.stopProgress();
                    return;
                }
                let user = await this.data.confirmSignIn(
                    this.user,
                    code,
                    this.user.challengeName === 'SMS_MFA' ? 'SMS_MFA' : 'SOFTWARE_TOKEN_MFA'
                )
                this.userDetails = user;
                let realms = await this.data.getRealms(user);
                let accessToken = await this.data.getAccessToken(user);
                await this.data.reauthSessionPost(accessToken);
                if(realms == false){
                    this.common.stopProgress();
                    this.closeSessions = true;
                }
                else {
                    this.closeSessions = false;
                    this.setRealmsAndRedirect(realms);
                    return;
                }
            } else { //if code not sent
                let user = await this.data.login(username, password);
                this.userDetails = user;
                //if 2nd factor required
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    this.codeText = user.challengeName === 'SMS_MFA' ? this.tr('access.login.codePhoneText') + this.maskPhoneumber(user.challengeParam.CODE_DELIVERY_DESTINATION) : this.tr('access.login.codeAppText');
                    // You need to get the code from the UI inputs
                    // and then trigger the following function with a button click
                    this.codeSent = true;
                    this.common.title = this.tr("access.login.2stepverification");
                    this.user = user;
                    var res = await this.data.getUser(username, user.pool.userPoolId).toPromise();
                    res = JSON.parse(this.config.decrypt(username, res.user));
                    if (res.UserMFASettingList.length > 1) {
                        this.phoneNumber = res.UserAttributes.find(attr => attr.Name === 'phone_number').Value;
                        this.bothMFAEnabled = true;
                        this.preferredMethod = res.PreferredMfaSetting;
                    }
                    this.common.stopProgress();
                    return;
                } else {
                    let realms = await this.data.getRealms(user);
                    let accessToken = await this.data.getAccessToken(user);
                    await this.data.reauthSessionPost(accessToken);
                    if (user.attributes['custom:mfa'] == 'optional' || user.attributes['custom:mfa'] == 'mandatory') {
                        this.allowRedirect = false;
                        this.setupMFA();
                    }
                    if(realms == false){
                        this.common.stopProgress();
                    }
                    else {
                        this.closeSessions = false;
                        this.setRealmsAndRedirect(realms);
                    }
                }
            }
        }
        catch (err) {
            if (err.name == 'NotAuthorizedException') {
                this.common.setError(err.message);
            } else if (err.name == 'CodeMismatchException') {
                this.invalidCode = true;
            }else if(err.name == "UserLambdaValidationException" && err.message.includes('PreAuthentication failed')){
                this.common.setError(err.message.substring(err.message.indexOf("error") + 6 , err.message.length))
            }
            else {
                console.log(err);
                this.common.setError(this.tr('access.login.incorrect'));
            }
            this.common.stopProgress();
        }
    }

    async changeMFAMethod(email, password){
        if(this.preferredMethod == "SMS_MFA"){
          await this.data.setMFA(this.user.pool.userPoolId, email, {
                enabled: true,
                preferred: false
            }, {
                enabled: true,
                preferred: true
            });
            this.codeText = this.tr('access.login.codeAppText');
            this.preferredMethod = "SOFTWARE_TOKEN_MFA";
        } else if (this.preferredMethod == "SOFTWARE_TOKEN_MFA") {
            await this.data.setMFA(this.user.pool.userPoolId, email, {
                enabled: true,
                preferred: true
            }, {
                enabled: true,
                preferred: false
            });
            this.preferredMethod = "SMS_MFA"
            this.codeText = this.tr('access.login.codePhoneText') + this.maskPhoneumber(this.phoneNumber);
        }
        this.user = await this.data.login(email, password);
    }

    setupMFA() {
        if(this.user && this.user.attributes['custom:mfa'] && this.user.attributes['custom:mfa']  == 'mandatory'){
            localStorage.setItem('mfa','mandatory');
        }
        let url = '/setupmfa?redirect=true&origin=' + encodeURIComponent(this.originUrl);
        url += '&target=' + encodeURIComponent(this.targetUrl);
        if(this.config.getParameterByName('languagecode')) {
            url += '&languagecode=' + encodeURIComponent(this.config.getParameterByName('languagecode'));
        }
        this.router.navigateByUrl(url);
        this.common.stopProgress();
    }

    setRealmsAndRedirect(realms) {
        // if (realms.length > 1) {
        //     this.multipleRealms = true;
        //     this.realms = realms;
        // }
        // Otherwise select first and redirect
        if (realms.length != 0) {
            this.config.setCookie('AccessRealm', realms[0].realm_id, 1);
            this.accessReAuthRedirect();
        } else {
            this.accessReAuthRedirect();
        }
        this.common.stopProgress();
    }


    async formButton(password: string, code: string) {
        if (!this.discovered)
            this.discover(this.username);
        else
            this.login(this.username, password, code);

        return false;
    }

    buttonText() {
        if (!this.discovered || this.codeSent)
            return this.tr('access.login.next');
        else if(this.closeSessions)
            return ('Login and close active sessions');
        else
            return this.tr('access.login.next');
    }

    tr(text: string) {
        return this.localization.translate(text);
    }
}
